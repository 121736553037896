import DISPLAY_TYPE from 'src/constants/displayType';

export const COMPRESSOR_FIELD_NAMES = {
  COMPRESSOR_ID: 'COMPRESSOR_ID',
  COMPRESSOR_TAG: 'COMPRESSOR_TAG',
  COMPRESSOR_MAKE: 'COMPRESSOR_MAKE',
  COMPRESSOR_MODEL: 'COMPRESSOR_MODEL',
  COMPRESSOR_TYPE: 'COMPRESSOR_TYPE',
  NAMEPLATE_HP: 'NAMEPLATE_HP',
  NAMEPLATE_CAPACITY: 'NAMEPLATE_CAPACITY',
  MOTOR_EFFICIENCY: 'MOTOR_EFFICIENCY',
  MOTOR_VOLTAGE: 'MOTOR_VOLTAGE',
  FULL_LOAD_CURRENT: 'FULL_LOAD_CURRENT',
  FULL_LOAD_INPUT_POWER: 'FULL_LOAD_INPUT_POWER',
  RATED_PRESSURE: 'RATED_PRESSURE',
  PRESSURE_SETPOINT: 'PRESSURE_SETPOINT',
  CONTROL: 'CONTROL',
  TOTAL_AIR_STORAGE_SIZE: 'TOTAL_AIR_STORAGE_SIZE',
  IS_ACTIVE: 'IS_ACTIVE',
  OPERATION_TYPE: 'OPERATION_TYPE',
  PERCENT_LOAD: 'PERCENT_LOAD',
  COMPRESSOR_SETPOINT_SEU: 'COMPRESSOR_SETPOINT_SEU',
  COMPRESSOR_FLOW_SEU: 'COMPRESSOR_FLOW_SEU',
  SYSTEM_PRESSURE_SETPOINT: 'SYSTEM_PRESSURE_SETPOINT',
  SYSTEM_MEASURED_FLOW: 'SYSTEM_MEASURED_FLOW',
  DATE_TIME_RUNNING_STATE: 'DATE_TIME_RUNNING_STATE',
  NOTES: 'NOTES',
  OPERATING_HOURS: 'OPERATING_HOURS',
  BHP: 'BHP',
  LOADED_PERCENT_TIME_RUNNING: 'LOADED_PERCENT_TIME_RUNNING',
  LOADED_PERCENT_FULL_LOAD_BHP: 'LOADED_PERCENT_FULL_LOAD_BHP',
  LOADED_MOTOR_EFFICIENCY: 'LOADED_MOTOR_EFFICIENCY',
  UNLOADED_PERCENT_TIME_RUNNING: 'UNLOADED_PERCENT_TIME_RUNNING',
  UNLOADED_PERCENT_FULL_LOAD_BHP: 'UNLOADED_PERCENT_FULL_LOAD_BHP',
  UNLOADED_MOTOR_EFFICIENCY: 'UNLOADED_MOTOR_EFFICIENCY',
  ENERGY_COST: 'ENERGY_COST',
  ANNUAL_ENERGY_COST: 'ANNUAL_ENERGY_COST',
  ANNUAL_ENERGY_KWH: 'ANNUAL_ENERGY_KWH',
  COST_FULLY_LOADED: 'COST_FULLY_LOADED',
  COST_UNLOADED: 'COST_UNLOADED',
  KWH_PER_YEAR_LOADED: 'KWH_PER_YEAR_LOADED',
  KWH_PER_YEAR_UNLOADED: 'KWH_PER_YEAR_UNLOADED',
  ENERGY_COST_PER_UNIT: 'ENERGY_COST_PER_UNIT',
};

export const COMPRESSOR_FIELD_PATHS = {
  COMPRESSOR_ID: 'id',
  COMPRESSOR_TAG: 'compressorTag',
  COMPRESSOR_MAKE: 'compressorMake',
  COMPRESSOR_MODEL: 'compressorModel',
  COMPRESSOR_TYPE: 'compressorType',
  NAMEPLATE_HP: 'namePlateHp',
  NAMEPLATE_CAPACITY: 'namePlateCapacity',
  MOTOR_EFFICIENCY: 'motorEfficiency',
  MOTOR_VOLTAGE: 'motorVoltage',
  FULL_LOAD_CURRENT: 'fullLoadCurrent',
  FULL_LOAD_INPUT_POWER: 'fullLoadInputPower',
  RATED_PRESSURE: 'ratedPressure',
  PRESSURE_SETPOINT: 'pressureSetpoint',
  CONTROL: 'control',
  TOTAL_AIR_STORAGE_SIZE: 'totalAirStorageSize',
  IS_ACTIVE: 'isActive',
  OPERATION_TYPE: 'operationType',
  PERCENT_LOAD: 'percentLoad',
  COMPRESSOR_SETPOINT_SEU: 'compressorSetpoint',
  COMPRESSOR_FLOW_SEU: 'compressorFlow',
  SYSTEM_PRESSURE_SETPOINT: 'systemPressureSetpoint',
  SYSTEM_MEASURED_FLOW: 'systemMeasuredFlow',
  DATE_TIME_RUNNING_STATE: 'dateTimeRunningState',
  NOTES: 'notes',
  OPERATING_HOURS: 'operatingHours',
  BHP: 'bhp',
  LOADED_PERCENT_TIME_RUNNING: 'loadedPercentTimeRunning',
  LOADED_PERCENT_FULL_LOAD_BHP: 'loadedPercentFullLoadBhp',
  LOADED_MOTOR_EFFICIENCY: 'loadedMotorEfficiency',
  UNLOADED_PERCENT_TIME_RUNNING: 'unloadedPercentTimeRunning',
  UNLOADED_PERCENT_FULL_LOAD_BHP: 'unloadedPercentFullLoadBhp',
  UNLOADED_MOTOR_EFFICIENCY: 'unloadedMotorEfficiency',
  ANNUAL_ENERGY_COST: 'aggregates.annualEnergyCost',
  ANNUAL_ENERGY_KWH: 'aggregates.annualEnergyKwh',
  COST_FULLY_LOADED: 'aggregates.costFullyLoaded',
  COST_UNLOADED: 'aggregates.costUnloaded',
  KWH_PER_YEAR_LOADED: 'aggregates.kwhPerYearLoaded',
  KWH_PER_YEAR_UNLOADED: 'aggregates.kwhPerYearUnloaded',
  ENERGY_COST_PER_UNIT: 'aggregates.energyCostPerUnit',
};

export const COMPRESSOR_FIELD_TYPES = {
  NAMEPLATE_HP: DISPLAY_TYPE.ROUNDED_NUMBER,
  NAMEPLATE_CAPACITY: DISPLAY_TYPE.ROUNDED_NUMBER,
  MOTOR_EFFICIENCY: DISPLAY_TYPE.ROUNDED_NUMBER,
  MOTOR_VOLTAGE: DISPLAY_TYPE.ROUNDED_NUMBER,
  FULL_LOAD_CURRENT: DISPLAY_TYPE.ROUNDED_NUMBER,
  FULL_LOAD_INPUT_POWER: DISPLAY_TYPE.ROUNDED_NUMBER,
  RATED_PRESSURE: DISPLAY_TYPE.ROUNDED_NUMBER,
  PRESSURE_SETPOINT: DISPLAY_TYPE.ROUNDED_NUMBER,
  TOTAL_AIR_STORAGE_SIZE: DISPLAY_TYPE.ROUNDED_NUMBER,
  IS_ACTIVE: DISPLAY_TYPE.BOOLEAN,
  PERCENT_LOAD: DISPLAY_TYPE.ROUNDED_NUMBER,
  COMPRESSOR_SETPOINT_SEU: DISPLAY_TYPE.ROUNDED_NUMBER,
  COMPRESSOR_FLOW_SEU: DISPLAY_TYPE.ROUNDED_NUMBER,
  OPERATING_HOURS: DISPLAY_TYPE.ROUNDED_NUMBER,
  BHP: DISPLAY_TYPE.ROUNDED_NUMBER,
  LOADED_PERCENT_TIME_RUNNING: DISPLAY_TYPE.ROUNDED_NUMBER,
  LOADED_PERCENT_FULL_LOAD_BHP: DISPLAY_TYPE.ROUNDED_NUMBER,
  LOADED_MOTOR_EFFICIENCY: DISPLAY_TYPE.ROUNDED_NUMBER,
  UNLOADED_PERCENT_TIME_RUNNING: DISPLAY_TYPE.ROUNDED_NUMBER,
  UNLOADED_PERCENT_FULL_LOAD_BHP: DISPLAY_TYPE.ROUNDED_NUMBER,
  UNLOADED_MOTOR_EFFICIENCY: DISPLAY_TYPE.ROUNDED_NUMBER,
};

export const COMPRESSOR_FIELD_PRECISIONS = {
  KWH_PER_YEAR_LOADED: 0,
  KWH_PER_YEAR_UNLOADED: 0,
  ANNUAL_ENERGY_KWH: 0,
  COST_FULLY_LOADED: 2,
  COST_UNLOADED: 2,
  ANNUAL_ENERGY_COST: 2,
  ENERGY_COST_PER_UNIT: 6,
};

export const LABELS = {
  COMPRESSOR_TAG: 'Compressor Tag',
  COMPRESSOR_MAKE: 'Compressor Make',
  COMPRESSOR_MODEL: 'Compressor Model',
  COMPRESSOR_TYPE: 'Compressor Type',
  NAMEPLATE_HP: 'Nameplate HP',
  NAMEPLATE_CAPACITY: 'Nameplate Capacity',
  MOTOR_EFFICIENCY: 'Motor Efficiency (%)',
  MOTOR_VOLTAGE: 'Motor Voltage',
  FULL_LOAD_CURRENT: 'Full Load Current (Amps)',
  FULL_LOAD_INPUT_POWER: 'Full Load Input Power (kW)',
  RATED_PRESSURE: 'Rated Pressure',
  PRESSURE_SETPOINT: 'Pressure Setpoint',
  CONTROL: 'Control',
  TOTAL_AIR_STORAGE_SIZE: 'Total Air Storage Size',
  IS_ACTIVE: 'Compressor Active / Off',
  OPERATION_TYPE: 'Trim / Baseload',
  PERCENT_LOAD: '% Load',
  COMPRESSOR_SETPOINT_SEU: 'Compressor Setpoint',
  COMPRESSOR_FLOW_SEU: 'Compressor Flow',
  SYSTEM_PRESSURE_SETPOINT: 'System Pressure Setpoint',
  SYSTEM_MEASURED_FLOW: 'System Measured Flow',
  DATE_TIME_RUNNING_STATE: 'Date and Time of Running State',
  NOTES: 'Notes',
  NAMEPLATE: 'Compressor Name Plate',
  DATASHEET: 'Compressor Data Sheet',
  OPERATING_HOURS: 'Operating Hours',
  BHP: 'BHP',
  LOADED_PERCENT_TIME_RUNNING: '% Time Running',
  LOADED_PERCENT_FULL_LOAD_BHP: '% Full Load BHP (Capacity)',
  LOADED_MOTOR_EFFICIENCY: 'Motor Efficiency (%)',
  UNLOADED_PERCENT_TIME_RUNNING: '% Time Running',
  UNLOADED_PERCENT_FULL_LOAD_BHP: '% Full Load BHP (Capacity)',
  UNLOADED_MOTOR_EFFICIENCY: 'Motor Efficiency (%)',
  ENERGY_COST: 'Aggregate Electric Rate ($)',
  ANNUAL_ENERGY_COST: 'Annual Energy Cost ($)',
  ANNUAL_ENERGY_KWH: 'Annual Energy kWh',
  COST_FULLY_LOADED: 'Cost when Fully Loaded ($)',
  COST_UNLOADED: 'Cost when Unloaded ($)',
  KWH_PER_YEAR_LOADED: 'kWh per Year when Loaded',
  KWH_PER_YEAR_UNLOADED: 'kWh per Year when Unloaded',
  ENERGY_COST_PER_UNIT: 'Energy Cost per Unit',
};
